import React from 'react';

// Libraries
import tw from 'twin.macro';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${tw`
  grid grid-cols-2 lg:grid-cols-1
  h-full
  font-roboto
  justify-between
  text-center
  justify-items-center
  items-center
  p-10
  overflow-hidden
  m-auto
  `};
  max-width: 1425px;
`;

const StaticImg = styled.img`
  ${tw`
  fixed inset-x-0 bottom-0 w-full
    `}
`;
const Planet1 = styled.img`
  ${tw`
absolute left-0 top-40 h-80 w-80 lg:hidden
  `}
  transition-property: all;
  transition-property: transform;
  lg:transform: translate(-50%, 40%);
`;
const Planet2 = styled.img`
  ${tw`
absolute right-0 top-10 h-24 w-24 lg:hidden
  `}
  lg:transform: translate(-50%, 40%);
`;

const Filigrane = styled.img`
  ${tw`
absolute left-40 top-10 h-100 opacity-10
  `}
`;
const Container = ({ children, flex }) => (
  <Wrapper style={flex && { display: 'block' }}>
    {children}
    <StaticImg style={{ zIndex: -1 }} src='/bdaip-landscape-background.png' />
    <Planet1 src='/bdaip-planet-anneaux.png' />
    <Planet2 src='/bdaip-planet-green.png' />
    <Filigrane style={{ zIndex: -2 }} src='/bdap-filigrane.png' />
  </Wrapper>
);

export default Container;
